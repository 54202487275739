<template>
  <main class="page">
    <section class="section section__article-markdown">
      <Markdown class="article-markdown" :source="source" />
    </section>
  </main>
</template>

<script>
import Markdown from 'vue3-markdown-it'
import mdFile from './PrivacyPolicyForBrowserExtension/index.md'
export default {
  name: 'Terms.v2.Markdown',
  components: {
    Markdown,
  },
  computed: {
    config: {
      get() {
        return this.getConfig('PrivacyPolicyForBrowserExtension')
      },
    },
    source: {
      get() {
        if (!this.config) {
          return ''
        }
        return this.parse(mdFile)
      },
    },
  },
  watch: {
    mdFile(content) {
      if (content) {
        this.$nextTick(() => {
          this.setStyles()
        })
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setStyles()
    })
  },
  methods: {
    getConfig(name) {
      return require('./' + name + '/config.json')
    },
    parse(content) {
      let res = content
      if (typeof this.config.variables === 'object') {
        Object.entries(this.config.variables).forEach(([variable, value]) => {
          let preparedValue = ''
          let [preValue, realValue] = value.split('.')
          realValue = realValue || value
          if (preValue === 'env') {
            preparedValue = process.env[`VUE_APP__${realValue}`] || realValue
          } else {
            preparedValue = realValue
          }
          res = res.replaceAll(`%{${variable}}`, preparedValue)
        })
      }
      return res
    },
    setStyles() {
      if (!this.source) {
        return
      }
      const MD = document.getElementsByClassName('article-markdown')[0]
      if (!MD) {
        return
      }
      MD.getElementsByTagName('h1').forEach((h1) => {
        h1.classList.add('title', 'title-lg', 'text-center', 'mb-4')
      })
      MD.getElementsByTagName('p').forEach((p) => {
        p.classList.add('mb-3', 'text', 'text-md')
      })
      MD.getElementsByTagName('ul').forEach((ul) => {
        ul.classList.add('mb-3')
      })
      MD.getElementsByTagName('ol').forEach((ol) => {
        ol.classList.add('mb-3')
      })
      MD.getElementsByTagName('li').forEach((li) => {
        li.classList.add('mb-2', 'text', 'text-md')
      })
      MD.getElementsByTagName('a').forEach((li) => {
        li.classList.add('link', 'link-type_default')
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
